import React, {useEffect,useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';

const App = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [thinking, setThinking] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [rewardData, setRewardData] = useState(false);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'environment', // Switch between front and back cameras
  };
  useEffect(() => {
    console.log(2);
    window.scrollTo({
      top: 1000, 
      behavior: 'smooth', 
    });
  }, []); 

  const startCamera = () => {
    setCapturedImage(null); // Reset any captured image
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc); // Set the captured image for preview
  };

  const restartapp = () => {
    window.location.reload();
  };

  

  const sendToServer = async() => {
    if (capturedImage) {
      setThinking(true)
      // Remove the "data:image/jpeg;base64," part of the base64 string if present
      const base64Image = capturedImage.replace(/^data:image\/[a-z]+;base64,/, "");
      //  "https://vision.xertcoin.com/upload/image",
      // "http://localhost:5006/upload/image" 
      await axios
        .post(
          "https://vision.xertcoin.com/upload/image",
          { image: `data:image/jpeg;base64,${base64Image}` }, // Ensure image has the correct base64 structure
          {
            headers: {
              "Content-Type": "application/json", // Specify JSON content-type
            },
          }
        )
        .then((response) => {
          console.log("Image uploaded successfully", );
          setShowReward(true)
          setRewardData(response.data.file)
          setThinking(false)



          const myFunction = () => {

            const filePath = './rewards/0'+response.data.reward+'.jpg';
            fetch(filePath)
              .then((response) => response.blob())
              .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setRewardData(reader.result.split(',')[1]); 
                };
                reader.readAsDataURL(blob); 
              });

          };


          setTimeout(myFunction, 2000);

        })
        .catch((error) => {
          setShowReward(false)
          // console.error("Error uploading image", error);
        });

        setThinking(false)
    }
  };
  // const switchCamera = () => {
  //   setIsFrontCamera((prev) => !prev); // Toggle between front and back camera
  // };

  return (
    <div style={{ width: "100vw", height: "100vh", position: "relative", overflow: "hidden" }}>

{showReward && !thinking && (
    <div
    style={{
      backgroundColor: 'white',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img
      src={`data:image/jpeg;base64,${rewardData}`}
      alt="Centered"
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
      }}
    />
    
  </div>
      )}

      {thinking && !showReward &&(
    <div
    style={{
      backgroundColor: 'white',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img
      src="./200.gif"
      alt="Centered"
      style={{
        maxWidth: '100%',
        maxHeight: '80%',
        objectFit: 'contain',
      }}
    />
    <p style={{ marginTop: '20px', fontSize: '18px', color: 'black' }}>
      Please wait...
    </p>
  </div>
      )}
 
      {/* If there's no captured image, show the webcam */}
      {!capturedImage && !thinking && !showReward && (
       <Webcam
       audio={false}
       ref={webcamRef}
       screenshotFormat="image/jpeg"
       videoConstraints={videoConstraints}
       style={{ position: "absolute", width: "100vw", height: "100vh", objectFit: "cover" }}
     />
      )}

      {/* If an image is captured, show it as a preview */}
      {capturedImage && !thinking && !showReward && (
        <img
          src={capturedImage}
          alt="Captured"
          style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover" }}
        />
      )}


{capturedImage && !thinking && !showReward &&(
<div style={{ position: "absolute", bottom: "20px", left: "2%" }}>
  <button
    onClick={startCamera}
    style={{
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      backgroundColor: "gray",
      border: "none",
      display: "flex",
      opacity:"80%",
      justifyContent: "center",
      alignItems: "center",
      transition: "transform 0.1s ease",
      outline: "none",
    }}
    onMouseDown={(e) => {
      e.target.style.transform = "scale(0.95)";
    }}
    onMouseUp={(e) => {
      e.target.style.transform = "scale(1)";
    }}
  >
    <div
      style={{
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="./icons8-retry-500.svg"
        alt="Retry Icon"
        style={{ width: "40px", height: "40px" }}
      />
    </div>
  </button>
</div>

      )}

{capturedImage && !thinking && showReward &&(
   <div
   style={{
     position: "absolute",
     bottom: "20px", // 
     left: "50%",
     transform: "translateX(-50%)", 
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
   }}
 >
   <button
     onClick={restartapp}
     style={{
       width: "80px",
       height: "80px",
       borderRadius: "50%",
       backgroundColor: "gray",
       border: "none",
       display: "flex",
       opacity: "80%",
       justifyContent: "center",
       alignItems: "center",
       transition: "transform 0.1s ease",
       outline: "none",
     }}
     onMouseDown={(e) => {
       e.target.style.transform = "scale(0.95)";
     }}
     onMouseUp={(e) => {
       e.target.style.transform = "scale(1)";
     }}
   >
     <div
      style={{
        width: "60px",
        height: "60px",
        borderRadius: "50%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="./icons8-retry-500.svg"
        alt="Retry Icon"
        style={{ width: "40px", height: "40px" }}
      />
    </div>
   </button>
 </div>

      )}


      {/* Bottom-Middle: Take a Picture Button (only show when camera is active) */}
      {!capturedImage && !thinking && !showReward &&(
       <div
       style={{
         position: "absolute",
         bottom: "20px", // 
         left: "50%",
         transform: "translateX(-50%)", 
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
       }}
     >
       <button
         onClick={capturePhoto}
         style={{
           width: "80px",
           height: "80px",
           borderRadius: "50%",
           backgroundColor: "gray",
           border: "none",
           display: "flex",
           opacity: "80%",
           justifyContent: "center",
           alignItems: "center",
           transition: "transform 0.1s ease",
           outline: "none",
         }}
         onMouseDown={(e) => {
           e.target.style.transform = "scale(0.95)";
         }}
         onMouseUp={(e) => {
           e.target.style.transform = "scale(1)";
         }}
       >
         <div
           style={{
             width: "50px",
             height: "50px",
             borderRadius: "10px",
             backgroundColor: "white",
           }}
         ></div>
       </button>
     </div>
      )}

      {/* Bottom-Right: Send Button (only show after capturing an image) */}
      {capturedImage && !thinking && !showReward &&(
      
        <div style={{ position: "absolute", bottom: "20px", right: "2%" }}>
        <button
          onClick={sendToServer}
          style={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            backgroundColor: "gray",
            border: "none",
            display: "flex",
            opacity:"80%",
            justifyContent: "center",
            alignItems: "center",
            transition: "transform 0.1s ease",
            outline: "none",
          }}
          onMouseDown={(e) => {
            e.target.style.transform = "scale(0.95)";
          }}
          onMouseUp={(e) => {
            e.target.style.transform = "scale(1)";
          }}
        >
          <div
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="./upload-minimalistic-svgrepo-com.svg"
              alt="Retry Icon"
              style={{ width: "40px", height: "40px" }}
            />
          </div>
        </button>
      </div>
      
      )}

    </div>
  );
};

export default App;
